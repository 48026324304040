import '../styles/tailwind.css';
import { AppProps } from 'next/app';
import Main from '@/components/layouts/Main';
import React, { FC, useEffect } from 'react';
import { NextPage } from 'next';
import { Provider } from '@/components/App/Store';
import LoadingAppBar from './LoadingAppBar';
import useOAuthUser from '@/components/hooks/useOAuthUser';
import { tryOAuthLoginWithRefreshToken } from '@/lib/oAuth';

export type PageWithLayout<T = {}> = NextPage<T> & {
  Layout?: PageWithLayout;
};

export type MyAppProps<T = any> = AppProps<T> & { Component: PageWithLayout };

const EmptyMainLayout: FC = ({ children }) => <>{children}</>;

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  const { mutateOAuth } = useOAuthUser();
  useEffect(() => {
    tryOAuthLoginWithRefreshToken().then(() => mutateOAuth());
  }, []);
  const LayoutA: PageWithLayout = Component.Layout || Main;
  const LayoutB = LayoutA.Layout ? LayoutA.Layout : EmptyMainLayout;
  return (
    <Provider>
      <LoadingAppBar />
      <LayoutB {...pageProps}>
        <LayoutA {...pageProps}>
          <Component {...pageProps} />
        </LayoutA>
      </LayoutB>
    </Provider>
  );
};

export default MyApp;
