import React, { CSSProperties, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const common: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  border: 'none',
  borderRadius: 0,
  backgroundColor: 'currentColor',
  zIndex: 10000,
  height: 2,
  color: '#00ff00',
  opacity: 0,
  width: '0%',
};
const loadingStyles: CSSProperties = {
  ...common,
  opacity: 1,
  width: '99%',
  transition: 'width 10s cubic-bezier(0.1, 0.05, 0, 1)',
};
const finishStyles: CSSProperties = {
  ...common,
  opacity: 0,
  width: '100%',
  transition: 'width 0.1s ease-out, opacity 0.5s ease 0.2s',
};

const startStyle: CSSProperties = {
  ...common,
  opacity: 0.4,
  boxShadow: '3px 0 8px',
  height: '100%',
};

export default function LoadingAppBar({ delay = 150 }: { delay?: number }) {
  const router = useRouter();
  const [styles, setStyles] = useState(startStyle);
  const [, setTimer] = useState(0);
  useEffect(() => {
    const start = () => {
      setStyles(startStyle);
      setTimer((prev) => {
        if (prev) {
          clearTimeout(prev);
        }
        return 0;
      });

      setTimer(
        // @ts-ignore
        setTimeout(() => {
          setStyles(loadingStyles);
        }, delay),
      );
    };
    const done = () => {
      setTimer((prev) => {
        if (prev) {
          clearTimeout(prev);
        }
        setStyles(finishStyles);
        return 0;
      });
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', done);
    router.events.on('routeChangeError', done);
    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', done);
      router.events.off('routeChangeError', done);
    };
  }, [router.events]);

  return <div style={styles} />;
}
